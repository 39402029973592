

// import Loader from '@/components/Loader.vue'
export default {
  name: 'Home',
  components: {
    // Loader
  },
  head() {
    return {
     // title: this.$t('home.metaTitle'),
          title: 'Location showroom paris, fashion week showroom rental, showroom mode paris, couture week showroom',
      meta: [
        //{ hid: 'description', name: 'description', content: this.$t('home.metaDescription') },
        { hid: 'description', name: 'description', content: this.$t('Rent a showroom for the Fashion Week in Paris? Contact our fashion specialists. Location showroom paris, fashion week showroom rental, showroom mode paris.') },
        { hid: 'og:title', name: 'og:title', content: this.$t('home.metaTitle') },
        //{ hid: 'og:description', name: 'og:description', content: this.$t('home.metaDescription') },
        { hid: 'og:description', name: 'og:description', content: this.$t('Rent a showroom for the Fashion Week in Paris? Contact our fashion specialists. Location showroom paris, fashion week showroom rental, showroom mode paris.') },
        { hid: 'og:image', name: 'og:image', content: '../assets/img/showroom-fashionweek-bg.webp' },
        { hid: 'og:url', name: 'og:url', content: process.env.NUXT_ENV_LOCAL_URL }
      ],
      script: [{ type: 'application/ld+json', json: this.structuredData }]
    }
  },
  data() {
    return {
      // loading: false,
      email: '',
      displayNewsletter: false,
      structuredData: { // from https://schema.org/WebPage
        "@context": "https://schema.org",
        "@type": "WebPage",
        "description": this.$t('home.metaDescription'),
        "primaryImageOfPage": ['../assets/img/showroom-fashionweek-bg.webp'],
        "specialty": this.$t('home.metaTitle'),
        "url": process.env.NUXT_ENV_LOCAL_URL
      }
    }
  },
  beforeDestroy() {
    if(this.$device.isDesktop){
      window.removeEventListener('resize', this.videoHandler)
      window.removeEventListener('load', this.videoHandler)
    }
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('wheel', this.handleScroll)
  },
  mounted() {
    if(this.$device.isDesktop){
      window.addEventListener('resize', this.videoHandler)
      window.addEventListener('load', this.videoHandler)
    }
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('wheel', this.handleScroll)
    // Display Newsletter registration modal when mouse leave the document
    document.documentElement.addEventListener('mouseleave', () => {
      this.displayNewsletter = true
    })
  },
  async asyncData({ $axios, i18n }) {
    try {
      const heroTitle = await $axios.$get(
        process.env.NUXT_ENV_API_URL + '/contenu',
        {
          params: {
            name: i18n.locale === 'en' ? 'heroTitle_en' : 'heroTitle_fr'
          }
        }
      )
      return {heroTitle}
    } catch (error) {
      console.error(error)
    }
  },
  watch: {
    async '$i18n.locale'() {
      this.heroTitle = await this.getContent()
    }
  },
  methods: {
    async getContent () {
      try{
        const heroTitle = await this.$axios.$get(
          process.env.NUXT_ENV_API_URL + '/contenu',
          {
            params: {
              name: this.$i18n.locale === 'en' ? 'heroTitle_en' : 'heroTitle_fr'
            }
          }
        )
        return heroTitle
      } catch (e) {
        console.error(e)
      }
    },
    videoHandler() {
      // const vidMargTop = document.querySelector('.home video').clientHeight / 2
      const vidMargLeft = document.querySelector('.home video').clientWidth / 2
      // const vidTop = 'calc(50% - ' + vidMargTop + 'px)'
      const vidLeft = 'calc(50% - ' + vidMargLeft + 'px)'
      // document.querySelector('.home video').style.top = vidTop
      document.querySelector('.home video').style.left = vidLeft
    },
    handleScroll(e) {
      if (e.deltaY > 0) {
        window.removeEventListener('wheel', this.handleScroll)
        this.goToLieux()
      }
    },
    goToLieux() {
      this.$router.push(this.localePath({ name: 'lieux' }))
    }
  }
}
